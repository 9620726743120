@import "../../../Variables.module.scss";

.login {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: white;
    overflow-y: scroll;
    padding: 20px;
    .logo {
        margin: 0 auto;
        padding-top: 20px;
        width: fit-content;
    }
    .welcomeimage {
        max-width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        svg {
            width: 100%;
            height: auto;
            margin-left: -26px;
        }
    }
    .mobiletitle {
        font-family: 'SpareBank1-title-medium';
        font-weight: 500;
        font-size: 28px;
        color: #002776;
        margin: auto;
        max-width: 300px;
        margin-top: 10px;
        margin-bottom: 20px;
        @media (min-width: 765px) {
            display: none;
        }
    }

    .mobileparagraph {
        font-family: 'SpareBank1-regular';
        font-weight: 500;
        font-size: 16px;
        color: #002776;
        margin: auto;
        max-width: 300px;
        margin-top: 40px;
        margin-bottom: 20px;
        @media (min-width: 765px) {
            display: none;
        }
    }

    .logincontainer {
        max-width: 400px;
        width: 100%;
        margin: 0px auto;
        .desktoptitle {
            width: 100%;
            font-family: 'SpareBank1-title-medium';
            font-weight: 500;
            font-size: 32px;
            color: #002776;
            margin-bottom: 30px;
            @media (max-width: 765px) {
                display: none;
            }
        }
        .desktopparagraph {
            width: 100%;
            font-family: 'SpareBank1-regular';
            font-weight: 500;
            font-size: 16px;
            color: #002776;
            margin-bottom: 40px;
            @media (max-width: 765px) {
                display: none;
            }
        }
        .logintext {
            width: 100%;
            p.forgotpassword {
                text-align: center;
                display: block;
                color: #002776;
                margin-top: 32px;
                margin-bottom: 20px;
                text-decoration: underline;
                cursor: pointer;
            }
            p.forgotusername {
                font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
                font-weight: 500;
                color: #002776;
            }
        }
    }
    .logincontainer.errorcontainer {
        margin-top:200px;
    }
    @media (max-width: 765px) {
        .logo {
            padding-top: 0;
        }
        .welcomeimage {
            max-height: 150px;
        }
    }
}