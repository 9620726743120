.settings {
    .grid {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 0px 0px 0px 10px;
        @media (min-width: 1024px) {
            padding: 0px;
        }
        @media (max-width: 765px) {
            padding: 4px;
        }

        .version {
            p {
                font-size: 12px;
                color: #c1c1c1;
            }
        }
    }
}