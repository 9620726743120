.progressbar {
    padding: 10px;
    padding-bottom: 40px;
    padding-top: 30px;
    .bar {
        height: 5px;
        width: 100%;
        position: relative;
        border-radius: 5px;
        .background {
            width: 100%;
            height: 5px;
            background-color: lightgray;
            position: relative;
            border-radius: 5px;
            .fill {
                background-color: var(--color-secondary);
                height: 100%;
                border-radius: 5px;
            }
            .valuemarker {
                position: absolute;
                top: -3px;
                margin-left: -3px;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                border: 2px solid white;
                box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
                background-color: var(--color-primary);
            }
            .label {
                position: absolute;
                top: -24px;
                color: var(--color-primary);
                width: max-content;
                font-weight: 900;
                font-size: 14px;
                line-height: 16px;
                font-family: 'SpareBank1-regular';
            }
            .marker {
                position: absolute;
                color: gray;
                width: max-content;
                .markerline {
                    height: 16px;
                    width: 2px;
                    margin-top: -10px;
                    background-color: var(--color-secondary);
                }
                .markerlabel {
                    margin-top: 10px;
                    font-family: 'SpareBank1-regular';
                    font-weight: 900;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--color-primary);
                }
            }
            .minlabel {
                position: absolute;
                top: 15px;
                left: -2px;
                font-family: 'SpareBank1-regular';
                font-weight: 900;
                font-size: 12px;
                line-height: 16px;
                color: var(--color-primary);
            }
            .maxlabel {
                position: absolute;
                top: 15px;
                right: -2px;
                font-family: 'SpareBank1-regular';
                font-weight: 900;
                font-size: 12px;
                line-height: 16px;
                color: var(--color-primary);
            }
        }
    }
}