.popupcontainer {
    position: relative;
    border-radius: 8px;
    font-family: 'Sparebank1-regular';
    color:#020A0A;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left:10px;
    margin-bottom: 16px;

    @media (max-width:765px) {
        margin-left:0px;
    }

    @media (min-width:1024px) {
        max-width: calc(50% - 29px);
    }

    .infocontainer {
        background-color:#F8E9DD;
        border-radius: 8px;
        min-width: 100%;
        padding:32px;
        
        h2 {
            margin-top: 0px;
            text-align: center;
        }

    }

    .closebtn {
        position: absolute;
        top: 3px;
        right: 3px;
        cursor: pointer;
    }

    &.hidden {
        display: none;
        opacity: 0;
    }
}