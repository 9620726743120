// Blue
$ffe-blue-royal: #002776; // Titles, lead, overlays
$ffe-blue-cobalt: #005aa4; // Primary buttons, emphasis on blue areas and product cards
$ffe-blue-azure: #0071cd; // Secondary buttons
$ffe-blue-sky: #7fc6e8;
$ffe-blue-pale: #dff1f9; // Info-boxes, etc.
$ffe-blue-ice: #eff8fc; // Expandable table rows
$ffe-blue-focus: #a1dfff; // Focus on buttons and controls

// Green
$ffe-green: #37b441; // E.g. slider tool
$ffe-green-shamrock: #008a00; // Action buttons, slider tool, (should indicate affordance)
$ffe-green-emerald: #007b00;
$ffe-green-mint: #e1f4e3; // ~20% opacity of ffe-green-shamrock. (Info-boxes, etc.)

// Orange
$ffe-orange: #ff9100; // Campaigns
$ffe-orange-fire: #da3d00; // Form validation, Error messages
$ffe-orange-salmon: #f3bbaa; // ~20% opacity of ffe-orange-fire. (Info-boxes, etc.)

// Red
$ffe-red: #e60000;

// Purple
$ffe-purple: #c94096;
$ffe-purple-magenta: #a20076;
$ffe-purple-violet: #551a8b; // Visited links

// Beige
$ffe-sand: #f8f5eb; // $ffe-sand + $ffe-sand-25

// White
$ffe-white: #fff;

// Grey
$ffe-grey: #adadad;
$ffe-grey-cloud: #f4f4f4; // Background panels
$ffe-grey-silver: #ccc; // Lines, borders, inactive tool elements (slider, etc.)
$ffe-grey-charcoal: #676767;
$ffe-grey-warm: #f6f6f3;

// Black
$ffe-black: #262626; // Body text, and some other texts

// Deprecated
$ffe-blue-deep-sky: #008ed2; // Deprecated 05.2018 - Use $ffe-blue-azure in stead
$ffe-sand-ivory: #fbfaf5; // Deprecated 05.2018 - Use $ffe-sand or $ffe-grey-warm in stead

// Dark mode. Only for use in a native app context.
$ffe-black-darkmode: #000000; // Default background color
$ffe-white-darkmode: #ffffff; // Emphasized text
$ffe-grey-charcoal-darkmode: #1c1c1c; // Emphasized surfaces
$ffe-grey-darkmode: #292929; // Interactive surfaces
$ffe-grey-silver-darkmode: #858585; // Borders and accents
$ffe-grey-cloud-darkmode: #cccccc; // Default font color on default background and form labels
$ffe-blue-azure-darkmode: #0a91ff; // Primary interaction and icon color
$ffe-red-darkmode: #ff2424; // Tweaked ffe-red for better contrast

//
// ======== Ny visuell identitet ========
//

// Primærpalett
$ffe-farge-fjell: #002776;
$ffe-farge-fjell-70: tint($ffe-farge-fjell, 30%);
$ffe-farge-fjell-30: tint($ffe-farge-fjell, 70%);
$ffe-farge-vann: #005aa4;
$ffe-farge-vann-70: tint($ffe-farge-vann, 30%);
$ffe-farge-vann-30: tint($ffe-farge-vann, 70%);
$ffe-farge-sand: #f8e9dd;
$ffe-farge-sand-70: tint($ffe-farge-sand, 30%);
$ffe-farge-sand-30: tint($ffe-farge-sand, 70%);
$ffe-farge-frost: #7eb5d2;
$ffe-farge-frost-70: tint($ffe-farge-frost, 30%);
$ffe-farge-frost-30: tint($ffe-farge-frost, 70%);
$ffe-farge-syrin: #d3d3ea;
$ffe-farge-syrin-70: tint($ffe-farge-syrin, 30%);
$ffe-farge-syrin-30: tint($ffe-farge-syrin, 70%);

// Støttefarger
$ffe-farge-myrull: #fae4e0;
$ffe-farge-myrull-70: tint($ffe-farge-myrull, 30%);
$ffe-farge-myrull-30: tint($ffe-farge-myrull, 70%);
$ffe-farge-villblomst: #ee8d9c;
$ffe-farge-villblomst-70: tint($ffe-farge-villblomst, 30%);
$ffe-farge-villblomst-30: tint($ffe-farge-villblomst, 70%);
$ffe-farge-nordlys: #33af85;
$ffe-farge-nordlys-wcag: #247a5d;
$ffe-farge-nordlys-70: tint($ffe-farge-nordlys, 30%);
$ffe-farge-nordlys-30: tint($ffe-farge-nordlys, 70%);
$ffe-farge-lyng: #873953;
$ffe-farge-lyng-70: tint($ffe-farge-lyng, 30%);
$ffe-farge-lyng-30: tint($ffe-farge-lyng, 70%);
$ffe-farge-baer: #e44244;
$ffe-farge-baer-wcag: #e12d2f;
$ffe-farge-baer-70: tint($ffe-farge-baer, 30%);
$ffe-farge-baer-30: tint($ffe-farge-baer, 70%);
$ffe-farge-skog: #285949;
$ffe-farge-skog-70: tint($ffe-farge-skog, 30%);
$ffe-farge-skog-30: tint($ffe-farge-skog, 70%);
$ffe-farge-multe: #f8b181;
$ffe-farge-multe-70: tint($ffe-farge-multe, 30%);
$ffe-farge-multe-30: tint($ffe-farge-multe, 70%);
$ffe-farge-sol: #dc8000;
$ffe-farge-sol-70: tint($ffe-farge-sol, 30%);
$ffe-farge-sol-30: tint($ffe-farge-sol, 70%);

// Nøytrale farger
$ffe-farge-natt: #001032;
$ffe-farge-svart: #020a0a;
$ffe-farge-koksgraa: #323232;
$ffe-farge-moerkgraa: #676767;
$ffe-farge-graa: #adadad;
$ffe-farge-graa-wcag: #949494;
$ffe-farge-lysgraa: #d8d8d8;
$ffe-farge-moerkvarmgraa: #848383;
$ffe-farge-varmgraa: #9b9797;
$ffe-farge-lysvarmgraa: #d7d2cb;
$ffe-farge-hvit: #fff;
