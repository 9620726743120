@import "../../../Variables.module.scss";

.calculator {
    @media (min-width: 1024px) {
        [class*="maincol"]:nth-child(2) {
            padding: 0px;
            margin-left: -5px;
        }
    }
    .tabgroup {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .grid {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 0px 0px 0px 10px;
        @media (min-width: 1024px) {
            padding: 0px;
        }
        @media (max-width: 765px) {
            padding: 3px;
        }
    }
    @media (min-width: 1024px) {
        [class*='ffe-grid__col']:not([class*='maincol']) {
            padding: 0 8px 16px;
        }
    }
    p.starwarning {
        margin-top: 4px;
        font-size: 14px;
        font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
    }
    .costoverview {
        text-align: center;
        @media (min-width: 1024px) {
            position: fixed;
            top: 12px;
            min-width: 360px;
            max-height: calc(100vh - 25px);
            overflow-y: scroll;
            // Hide scrollbar
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track {
                background: #f8f8f8;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
        button:nth-child(1) {
            margin-bottom: 30px;
        }
        button.printbutton {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .costoverviewrefreshbtn {
            color: #e12d2f;
            &:hover {
                animation: none;
            }
            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            &.active {
                color: var(--color-secondary);
                &:hover {
                    animation: spin 2s linear infinite;
                }
            }
        }
        .resultslist {
            .result {
                overflow: auto;
                p {
                    margin: 12px 0px;
                }
                p:nth-child(2) {
                    color: var(--color-primary);
                    font-family: 'SpareBank1-medium', 'MuseoSansRounded-700', arial, sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    float: left;
                }
                p:nth-child(3) {
                    font-family: 'SpareBank1-medium', 'MuseoSansRounded-700', arial, sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    float: right;
                }
            }
            .totalcost {
                margin-bottom: 0px;
                text-align: left;
                font-size: 12px;
            }
            &.blurred {
                .result {
                    p:nth-child(3) {
                        filter: blur(3px);
                        margin-right: 4px;
                    }
                }
                .totalcost {
                    filter: blur(3px);
                }
            }
        }
    }
    .roundbuttongroup {
        padding-top: 36px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        .roundbutton {
            padding: 8px 8px;
            width: auto;
            //margin-right: 16px;
            display: inline-flex;
            border-color: var(--color-primary);
            &:nth-child(1) {
                color: var(--color-primary);
                &:hover {
                    color: white;
                }
            }
            &:nth-child(2) {
                background-color: var(--color-primary);
            }
            &:hover {
                background-color: var(--color-primary-light);
                border-color: var(--color-primary-light);
            }
        }
    }
    .checkboxinputgroup {
        display: flex;
        flex-direction: row-reverse;
        span {
            flex-grow: 1;
            width: 120px;
            button {
                margin-top: 7px;
                margin-bottom: 8px;
            }
        }
        .checkboxcontainer {
            flex-grow: 2;
            max-width: 200px;
        }
    }

    .problems {
        p {
            color: #e12d2f;
            margin-top: 0px;
        }
    }
}