.casecustomercard {
    .grid {
        font-family: 'Sparebank1-regular';
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 0px 0px 0px 10px;
        @media (min-width: 1024px) {
            padding: 0px;
        }
        @media (max-width: 765px) {
            padding: 3px;
        }
        p {
            color:var(--color-primary);
            display: flex;
            justify-content: center;
        }
    }
}