

.archiveitem {
    padding: 0px 0px;
    cursor: pointer;
    .top {
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        .left {
            h2 {
                font-family: 'SpareBank1-regular';
                font-weight: 600;
                font-size: 16px;
                color: var(--color-primary);
                margin: 0px;
                margin-bottom: 10px;
            }
            p {
                font-family: 'SpareBank1-regular';
                font-weight: 400;
                font-size: 16px;
                margin: 0px;
                margin-bottom: 4px;
            }
            p:nth-of-type(1) {
                
            }
            p:nth-of-type(2) {

            }
        }
        .right {
            h2 {
                font-family: 'SpareBank1-regular';
                font-weight: 600;
                font-size: 16px;
                color: var(--color-primary);
                margin: 0px;
                margin-bottom: 10px;
            }
        }
    }
    .bottom {
        padding-left: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right:10px;
        margin-top: 6px;
        margin-bottom: 10px;
        .pills {

        }
        .date {
            font-family: 'SpareBank1-regular';
            font-weight: 400;
            font-size: 16px;
            p {
                margin: 0px;
                margin-top: 10px;
            }
        }
        .locked {
            border: 1px solid var(--color-primary);
            border-radius: 50%;
            width: 31px;
            height: 31px;
            cursor: pointer;
            span {
                font-size: 22px;
                margin-top: 3px;
                color: var(--color-primary);
                display: flex;
                justify-content: center;
            }
            &:hover {
                border: 1px solid var(--color-primary-light);;
                span {
                    color: var(--color-primary-light);;
                }
            }
        }
    }
    &:hover {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        border-left: 4px solid var(--color-primary-light);
        margin-left: -30px;
        padding-left: 26px;
        .top {
            .left {
                h2 {
                    color: var(--color-primary-light);;
                }
            }
            .right {
                h2 {
                    color: var(--color-primary-light);;
                }
            }
        }
    }
}