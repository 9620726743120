@import "../../../Variables.module.scss";

.insuranceapplication {
    .grid {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 0px 0px 0px 10px;
        @media (min-width: 1024px) {
            padding: 0px;
        }
        @media (max-width: 765px) {
            padding: 0px;
        }
    }
    .carinfotoggle {
        margin: auto;
        margin-bottom: 30px;
    }
    .insurancetoggle {
        margin: auto;
        margin-bottom: 30px;
    }
    .checkboxinputgroup {
        display: flex;
        flex-direction: row-reverse;
        span {
            flex-grow: 1;
            width: 120px;
            button {
                margin-top: 7px;
                margin-bottom: 8px;
            }
        }
        .checkboxcontainer {
            flex-grow: 2;
            max-width: 200px;
        }
    }
    .problems {
        span {
            color: #e12d2f;
            font-size: 32px;
            width: 100%;
            margin-top: 20px;
            text-align: center;
        }
        p {
            color: #e12d2f;
        }
    }
    .loaninfoarea {
        padding: 10px 10px;
        h2 {
            font-family: 'SpareBank1-regular';
            font-weight: 700;
            font-size: 18px;
            color: var(--color-primary);
            margin-bottom: 10px;
        }
        p {
            display: block;
            font-family: 'SpareBank1-regular';
            font-weight: 400;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            span:nth-child(1) {
                float: left;
                color: black;
            }
            span:nth-child(2) {
                float: right;
                font-weight: 600;
                color: var(--color-primary);
            }
        }
    }
    .loancaseinfoarea {
        padding: 10px 10px;
        >p {
            color: black;
            display: block;
            margin: 0px;
            margin-bottom: 6px;
            font-family: 'SpareBank1-regular';
            font-size: 16px;
            font-weight: 400;
        }
        .loanstatus, .loantitle {
            color: var(--color-primary);
            font-weight: 600;
        }
        .loanpills {
            margin-top: 20px;
        }
        .warnings {
            display: flex;
            align-items: center;
            span {
                color: #e12d2f;
            }
            p {
                margin: 0px;
                margin-top: 2px;
                margin-left: 10px;
                font-family: 'SpareBank1-regular';
                font-size: 16px;
                font-weight: 400;
                color: #e12d2f;
            }
        }
    }
    .errorDetails {
        margin-top: 20px;
        h4 {
            &:hover {
                cursor: default;
            }
        }
        p {
            margin-left: auto;
            margin-right: auto;
            border-radius: 10px;
            font-family: "SpareBank1-regular";
            font-weight: 500;
            line-height: 24px;
        }
    }
}