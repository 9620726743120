.navdealerswitcher {
    min-height: 38px;
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
    background-color: white;
    margin-top: 20px;
    margin-left:0px;
    padding-left:0px;
    cursor: pointer;
    width: 100%;
    transition: all 0.3s ease;
    align-items: center;
    border-right: 4px solid var(--color-primary);
    z-index: 2;

    .selectdealer{
        display: flex;
        position: relative;
        align-items: center;
        padding-top: 2px;
        padding-bottom: 2px;
        top:2px;
        width: 100%;

        >span {
            background-color: white;
        }
    }
    span {
        transition: all 0.3s ease;
        &[class*="accounticon"] {
            position: absolute;
            font-size: 25.5px;
            padding-top:1px;
            margin-left: 1px;
            top: 4px;
            left: 4px;
            border-radius: 100%;
            z-index:2;
        }
        &[class*="spinbutton"] {
            position:absolute;
            font-size: 26px;
            margin-right: 2px;
            top:4px;
            right:-1px;
            border-radius: 100% 100% 100% 100%;
            z-index:2;
        }
    }
    &.dropdownopen {
        span:nth-of-type(2) {
            rotate: 180deg;
        }
    }    
    p {
        color: var(--color-primary);
        font-family: 'SpareBank1-regular';
        font-size: 16px;
        font-weight: 600;
        margin: 0px;
        margin-left: 4px;
        margin-right: 4px;
        overflow: hidden;
        width: calc(100% - 52px);

        margin: 5px 0px;
        margin-left: 36px;
        padding-bottom: 2px;
        line-height: 22px;

    
        span{
            display: inline-block;
        }
        
    }
    .dropdowncontent {
        background-color: white;
        width: 100%;
        box-shadow: 0px 0px 10px #00000021;
        //overflow: hidden;
        p {
            color: var(--color-primary);
            padding: 12px 24px;
            margin: 0px;
            width: 100%;
            &:hover {
                background-color: var(--color-primary-light);
                color: white;
                cursor: pointer;
            }

            &.activedealer {
                text-decoration: underline;
            }
        
        }
    }
}

@media (max-width: 765px) {
    .navdealerswitcher {
        border-right:unset;
    }
    .dropdowncontent {
        top:6px;
    }
}