.border {
    border: 7px solid #D8E9F2;
    background-color: #D8E9F2;
    border-radius: 10px;

    .fileuploadcontainer{
        font-family: 'Sparebank1-regular';
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width:100%;
        height:134px;
        overflow:hidden;
        background-color: #D8E9F2;
        border: 1px dashed var(--color-primary);
        border-radius: 10px;
        padding:23px;

        .fileuploadbutton {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            border-radius: 30px;
            position: center;
            width: 30%;
            height: 44px;
            font-size: 16px;
            background-color: white;
            color:var(--color-primary);
            border: 1px solid var(--color-primary);
            &:hover {
                background-color: var(--color-primary);
                color:white;
            }

        
        }
    }

    &.fileover {
        .fileuploadcontainer{
            border: 3px dashed var(--color-primary);
        }
    }
}