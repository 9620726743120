@import "../../../Variables.module.scss";

.feedbackModal {
    position: fixed;
    background-color: #0000008f;
    width: 100%;
    height: 100%;
    z-index: 10000;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.1s linear;
    padding: 20px;
    .content {
        width: fit-content;
        max-width: 700px;
        width: 100%;
        margin: auto;
        padding: 40px;
        background-color: white;
        box-shadow: 2px 2px 20px #00000057;
        position: relative;
        max-height: calc(100vh - 100px);
        overflow-y: scroll;
        overscroll-behavior: contain;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;  /* Chrome, Safari, Opera*/
        }
        .feedbackHeader {
            h1 {
                font-weight: 500;
                font-family: "SpareBank1-title-medium";
                font-size: 28px;
                margin-top: 0px;
                margin-bottom: 30px;
                color: var(--color-primary);
                text-align: center;
            }
            .closebtn {
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
                padding: 20px;
            }
        }
        .feedbackContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
                max-width: 300px;
            }
        }
    }
}