.yearcompare {
    .doughnutchart {
        height: 150px;
        .centertext {
            text-align: center;
            position: absolute;
            width: calc(100% - 32px);
            top: 103px;
            font-family: 'SpareBank1-regular';
            font-weight: 900;
            font-size: 16px;
            line-height: 13px;
            color: var(--color-primary);
            pointer-events: none;
        }
    }
    .doughnuttext {
        font-family: 'SpareBank1-regular';
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-primary);
        overflow: auto;
        p {
            margin: 0px;
        }
        .left {
            float: left;
            text-align: left;
        }
        .right {
            float: right;
            text-align: right;
        }
    }
}