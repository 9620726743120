:root {
  --ffe-blue-royal: #002776;
  --ffe-blue-cobalt: #005aa4;
  --ffe-blue-azure: #0071cd;
  --ffe-blue-sky: #7fc6e8;
  --ffe-blue-pale: #dff1f9;
  --ffe-blue-ice: #eff8fc;
  --ffe-blue-focus: #a1dfff;
  --ffe-green: #37b441;
  --ffe-green-shamrock: #008a00;
  --ffe-green-emerald: #007b00;
  --ffe-green-mint: #e1f4e3;
  --ffe-orange: #ff9100;
  --ffe-orange-fire: #da3d00;
  --ffe-orange-salmon: #f3bbaa;
  --ffe-red: #e60000;
  --ffe-purple: #c94096;
  --ffe-purple-magenta: #a20076;
  --ffe-purple-violet: #551a8b;
  --ffe-sand: #f8f5eb;
  --ffe-white: #fff;
  --ffe-grey: #adadad;
  --ffe-grey-cloud: #f4f4f4;
  --ffe-grey-silver: #ccc;
  --ffe-grey-charcoal: #676767;
  --ffe-grey-warm: #f6f6f3;
  --ffe-black: #262626;
  --ffe-blue-deep-sky: #008ed2;
  --ffe-sand-ivory: #fbfaf5;
  --ffe-black-darkmode: #000000;
  --ffe-white-darkmode: #ffffff;
  --ffe-grey-charcoal-darkmode: #1c1c1c;
  --ffe-grey-darkmode: #292929;
  --ffe-grey-silver-darkmode: #858585;
  --ffe-grey-cloud-darkmode: #cccccc;
  --ffe-blue-azure-darkmode: #0a91ff;
  --ffe-red-darkmode: #ff2424;
  --ffe-farge-fjell: #002776;
  --ffe-farge-fjell-70: #4d689f;
  --ffe-farge-fjell-30: #b3bed6;
  --ffe-farge-vann: #005aa4;
  --ffe-farge-vann-70: #4d8cbf;
  --ffe-farge-vann-30: #b3cee4;
  --ffe-farge-sand: #f8e9dd;
  --ffe-farge-sand-70: #faf0e7;
  --ffe-farge-sand-30: #fdf8f5;
  --ffe-farge-frost: #7eb5d2;
  --ffe-farge-frost-70: #a5cbe0;
  --ffe-farge-frost-30: #d8e9f2;
  --ffe-farge-syrin: #d3d3ea;
  --ffe-farge-syrin-70: #e0e0f0;
  --ffe-farge-syrin-30: #f2f2f9;
  --ffe-farge-myrull: #fae4e0;
  --ffe-farge-myrull-70: #fcece9;
  --ffe-farge-myrull-30: #fef7f6;
  --ffe-farge-villblomst: #ee8d9c;
  --ffe-farge-villblomst-70: #f3afba;
  --ffe-farge-villblomst-30: #fadde1;
  --ffe-farge-nordlys: #33af85;
  --ffe-farge-nordlys-wcag: #247a5d;
  --ffe-farge-nordlys-70: #70c7aa;
  --ffe-farge-nordlys-30: #c2e7da;
  --ffe-farge-lyng: #873953;
  --ffe-farge-lyng-70: #ab7487;
  --ffe-farge-lyng-30: #dbc4cb;
  --ffe-farge-baer: #e44244;
  --ffe-farge-baer-wcag: #e12d2f;
  --ffe-farge-baer-70: #ec7b7c;
  --ffe-farge-baer-30: #f7c6c7;
  --ffe-farge-skog: #285949;
  --ffe-farge-skog-70: #698b80;
  --ffe-farge-skog-30: #bfcdc8;
  --ffe-farge-multe: #f8b181;
  --ffe-farge-multe-70: #fac8a7;
  --ffe-farge-multe-30: #fde8d9;
  --ffe-farge-sol: #dc8000;
  --ffe-farge-sol-70: #e7a64d;
  --ffe-farge-sol-30: #f5d9b3;
  --ffe-farge-natt: #001032;
  --ffe-farge-svart: #020a0a;
  --ffe-farge-koksgraa: #323232;
  --ffe-farge-moerkgraa: #676767;
  --ffe-farge-graa: #adadad;
  --ffe-farge-graa-wcag: #949494;
  --ffe-farge-lysgraa: #d8d8d8;
  --ffe-farge-moerkvarmgraa: #848383;
  --ffe-farge-varmgraa: #9b9797;
  --ffe-farge-lysvarmgraa: #d7d2cb;
  --ffe-farge-hvit: #fff;
}
