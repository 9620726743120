.messagesbackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000008a;
    z-index: 1000;
    backdrop-filter: blur(6px);
}

.fsnotificationscontainer {
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    position: fixed;

    .fsnotifications {
        background-color: #fff;
        border-radius: 5px;
        margin: auto;
        max-width: 500px;
        margin-top: 20vh;
        padding: 20px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    
        .fsnotification {
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .content {
                    flex-grow: 1;

                    h3 {
                        margin: 0;
                        color: var(--color-primary);
                        font-family: "SpareBank1-regular";
                        font-weight: 600;
                        font-size: 16px;
                        padding: 0px 10px;
                    }
                }

                .icon {
                    color: var(--color-primary);

                    span {
                        font-size: 30px;
                    }
                }
            }

            p {
                font-size: 13px;
                font-weight: 500;
                font-family: "SpareBank1-regular";
                color: var(--color-primary);
            }

            .actions {
                display: flex;
                justify-content: flex-end;
                margin-top: 30px;

                button {
                    padding: 5px 10px;
                    margin: 0 5px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 13px;
                    font-weight: 500;
                    font-family: "SpareBank1-regular";
                    color: #fff;
                    transition: background-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

                    &.accept {
                        background-color: var(--color-primary);
                    }

                    &.reject {
                        background-color: #f00000;
                    }

                    &:hover {
                        filter: brightness(1.1);
                    }
                }
            }
        }

        .prevnotification, .nextnotification {
            position: relative;
            top: 30px;
            right: -20px;
            float: right;
            padding: 5px 13px;
            width: fit-content;
            display: flex;
            color: var(--color-primary);
            background-color: #fff;
            border-radius: 5px;
            cursor: pointer;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);

            p {
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                font-family: "SpareBank1-regular";

                display: flex;
                align-items: center;

                span {
                    margin-left: 5px;
                }
            }

            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }

            &.disabled {
                cursor: default;
                background-color: #f0f0f0;
                color: #b0b0b0;
            }
        }

        .prevnotification {
            left: -20px;
            right: 30px;
            float: left;

            p {
                span {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }
        }
    }

    .logoutbtn {
        position: fixed;
        bottom: 5px;
        left: 30px;
        color: white;
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
            font-size: 16px;
            font-weight: 500;
            font-family: "SpareBank1-regular";
            margin-left: 10px;
        }
    }
}

.messages {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1001;
    .floatingbutton {
        width: 40px;
        height: 40px;
        background-color: var(--color-primary);
        display: flex;
        border-radius: 8px;
        box-shadow: 0px 0px 6px #0000004f;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition:
            background-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1),
            color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
        span {
            font-size: 22px;
        }
        .newmessages {
            position: absolute;
            bottom: -5px;
            right: -5px;
            background-color: #f00000;
            color: white;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 500;
            font-family: "SpareBank1-regular";
        }
        &:hover {
            background-color: #fff;
            color: var(--color-primary);
        }
    }
    .container {
        position: fixed;
        width: 500px;
        max-width: calc(100% - 20px);
        background-color: #fff;
        box-shadow: 0 0 20px rgb(0 0 0 / 16%);
        border-radius: 5px;
        right: 10px;
        top: 70px;
        padding: 10px 20px;

        .tabs {
            display: flex;
            .tab {
                opacity: 0.6;
                flex: 1;
                text-align: center;
                padding: 10px;
                cursor: pointer;
                border-bottom: 2px solid #fff;
                &.active {
                    border-bottom: 2px solid var(--color-secondary);
                    transition: border-bottom 0.3s ease;
                    opacity: 1;
                }
                h3 {
                    margin: 0;
                    color: var(--color-primary);
                    font-family: "SpareBank1-regular";
                    font-weight: 600;
                    font-size: 16px;
                }
            }
        }
        .componentcontainer {
            margin-top: 20px;
        }
    }
}

@media (max-width: 765px) {
    .messages {
        left: 10px;
        right: unset;
        .floatingbutton {
            width: 40px;
            height: 40px;
            .newmessages {
                width: 16px;
                height: 16px;
                font-size: 11px;
            }
        }
        .container {
            left: 10px;
            right: unset;
        }
    }
}