
.printapplication {
    font-family: 'SpareBank1-regular';
    font-size: 14px;
    page-break-after: auto;

    h1, h2, h3, h4, h5, h6, p, ul, ol, li, table, tr, th, td {
        color: #000 !important;
    }

    .printheader {
        width: 100%;
        height: 100px;
        .printlogo {
            text-align: right;
        }
        .printdate {
            text-align: right;
        }
    }
    .printcalculationtable {
        margin-top: 10px;
        margin-bottom: 30px;
        table {
            width: 70%;
            border-collapse: collapse;
            thead {
                tr {
                    th {
                        border-bottom: 2px solid var(--color-primary);
                        &:nth-child(1) {
                            text-align: left;
                            font-size: 20px;
                        }
                        &:nth-child(2) {
                            text-align: right;
                            font-size: 28px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        border-bottom: 1px solid #c0c0c0;
                        &:nth-child(1) {
                            text-align: left;
                            font-size: 14px;
                        }
                        &:nth-child(2) {
                            text-align: right;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .printdealerinfo {
        margin-top: 40px;
        p {
            margin: 0px;
        }
    }
    .printfooter {
        position: fixed;
        bottom: 40px;
        width: calc(100% - 120px);
        p {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}