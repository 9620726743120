.fullscreenloading {
    width: 100vw;
    position: fixed;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 10000;
    text-align: center;
    padding-top: 200px;
    background-color: rgba(0, 0, 0, 0.5411764706);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    [class*="ffe-loading-spinner"]::before {
        height: 80px;
        width: 80px;
        border: 5px solid #ffffff;
        border-top-color: transparent;
    }
    &[class*="visibilityhidden"] {
        visibility: hidden;
        backdrop-filter: none;
        pointer-events: none;
        [class*="ffe-loading-spinner"]::before {
            border: none;
        }
    }
    p {
        font-family: 'Sparebank1-regular';
        color: white;
        font-size: 22px;
    }
}