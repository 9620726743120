@import "../../../ffescssvariables.scss";
@import "../../../Variables.module.scss";

.pill {
    border: 1px solid var(--color-primary);
    border-radius: 8px;
    padding: 6px 12px;
    float: left;
    margin-right: 10px;
    display: flex;
    align-items: center;
    i {
        margin-right: 6px;
        color: #e22d50;
        font-size: 18px;
    }
    p {
        font-family: 'SpareBank1-regular';
        font-weight: 400;
        font-size: 14px;
        margin: 0px;
        margin-top: 2px;
    }
}

.pillKontroll, .pillGodkjent, .pillAvvistman {
    border-width: 2px;

    i {
        font-size: 20px;
        margin-right: -6px;
        margin-left: 6px;
    }
}

.pillKontroll {
    border-color: $color-popcorn;

    i {
        color: $color-popcorn;
    }
}
.pillGodkjent {
    border-color: $ffe-farge-nordlys;

    i {
        color: $ffe-farge-nordlys;
    }
}
.pillAvvistman {
    border-color: $ffe-farge-baer;

    i {
        color: $ffe-farge-baer;
    }
}

//Darkmode
[class*="_app_"] {
    &[class*="dark"] {
        .pillKontroll {
            border-color: $color-popcorn;

            i {
                color: $color-popcorn;
            }
        }
        .pillGodkjent {
            border-color: $ffe-farge-nordlys;

            i {
                color: $ffe-farge-nordlys;
            }
        }
        .pillAvvistman {
            border-color: $ffe-farge-baer;

            i {
                color: $ffe-farge-baer;
            }
        }
    }
}