.searchfieldcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border: 2px solid var(--color-primary);
    border-radius: 30px;
    position: relative;
    width:100%;
    overflow:hidden;
    justify-content: space-between;
    height: 44px;


    .searchfield {
        display: flex;
        color:var(--color-primary);
        width:100%;
        font-family: "SpareBank1-regular",arial,sans-serif;
        font-size: 16px;
        input {
            color: var(--color-primary);
            border: white;
            height: 40px;
            align-items: center;
            width: 100%;
            padding-left:14px;
        }
        input::placeholder {
            color:var(--color-primary);
        }
        input:focus::placeholder {
            color: transparent;
            }
        input:focus-visible {
            outline: 0;
        }
    }

    .symbols {
        display: flex;
        cursor: pointer;
        width: fit-content;
        align-items: center;
        width: 50px;
        height: 40px;
        padding-left:10px;

    }   span {
        color:var(--color-primary);
    }
}
