.caseinsurancecard {
    .insurancetoggle {
        margin: auto;
        margin-bottom: 30px;
    }
    .pricecontainer {
        margin-top: 20px;
        .pricelist {
            .pricelistitem {
                display: flex;
                justify-content: space-between;
                padding: 16px;
                cursor: pointer;
                h3 {
                    margin: 0px;
                    font-size: 16px;
                }
                p {
                    margin: 0px;
                    font-size: 14px;
                    span.price {
                        font-weight: 600;
                        margin-right: 5px;
                    }
                }
                &.active {
                    background-color: #ebebeb;
                    border-left: 4px solid var(--color-primary);
                }
                &:hover {
                    background-color: #dfdfdf;
                }
                .pricelistitemleft {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    p {
                        display: inline-block;
                    }
                }
                .pricelistitemright {
                    text-align: right;
                    display: flex;
                    align-items: center;
                }
            }
        }
        [class*="ffe-input-group"] {
            margin-top: 20px;
        }
        .ekundeSamtykke {
            margin-top: 20px;
            p {
                color: var(--ffe-g-text-color)
            }
            li {
                padding-bottom: 5px;
            }
            hr {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .insurancePriceLine {
                display: block;
                span {
                    font-weight: 600;
                }
            }

            .warning {
                color: #e12d2f;
            }
        }
    }
    p.tooltip {
        color: #020a0a;
        font-size: 0.875rem;
        display: inline-block;
    }
    .problems {
        p {
            color: #e12d2f;
        }
    }
}