.statisticscompetitiontable {
    padding: 10px;
    overflow-y: auto;
    max-height: 390px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    
    table {
        width: 100%;
        border-collapse: collapse;
        thead {
            th {
                padding: 2px 12px;
                font-family: 'SpareBank1-regular';
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                color: var(--color-primary);
                text-align: left;
                position: relative;
                span {
                    position: absolute;
                }
                &:nth-child(3) {
                    text-align: right;
                }
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    span {
                        text-decoration: none !important;
                    }
                }
            }
        }
        tbody {
            tr {
                padding: 0 0 0 0;
                margin: 0 0 0 0;
                /* &:nth-child(1) {
                    background: linear-gradient(270deg, #D3D3EA 26.01%, rgba(211, 211, 234, 0) 96.23%);
                } */
                td {
                    padding: 0px 12px;
                    padding-top: 2px;
                    margin: 0 0 0 0;
                    border-bottom: 1px solid #D3D3EA;
                    border-top: 1px solid #D3D3EA;
                    font-family: 'SpareBank1-regular';
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 32px;
                    text-align: left;
                    position: relative;
                    &:nth-child(1) {
                        text-align: left;
                        padding-left: 16px;
                    }
                    &:nth-child(3) {
                        text-align: right;
                        padding-left: 16px;
                    }
                    .rank {
                        display: block;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        position: absolute;
                        left: -9px;
                        top: 7px;
                        color: var(--color-primary);
                        border: 2px solid #D4D4EB;
                        &.first {
                            border-color: var(--color-secondary);
                        }
                        p {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            display: block;
                            margin: 0px;
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 17px;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}