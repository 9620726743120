@import "../../../Variables.module.scss";

.categoryselector {
    .button {
        margin-bottom: 20px;
        border-color: var(--color-primary);
        color: var(--color-primary);
        &:hover {
            background-color: var(--color-primary-light);
            border-color: var(--color-primary-light);
        }
    }
}