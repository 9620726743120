@import "../../../Variables.module.scss";

.selectorlist {
    .selector{
        display: flex;
        background-color:  #FFFFFF;
        border: 2px solid var(--color-primary);
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 14px;

        &.active {
            background-color:  var(--color-primary);
            .dotcontainer {
                .circle {
                    border-color: white;
                    .dot {
                        background-color: var(--color-primary-light);
                    }
                }
                .square {
                    border-color: white;
                    .squaredot {
                        background-color: var(--color-primary-light);
                    }
                }
            }
            .button{
                p{
                    color:white
                }
            }
        }

        &:hover:not(.disabled, .oneOption) {
            background-color: var(--color-primary-light);
            border-color: var(--color-primary-light);
            .button {
                p {
                    color:white
                }
            }
        }

        &.disabled, &.oneOption {
            cursor: default;
        }

        &.disabled {
            opacity: 50%;
        }
        
        .dotcontainer{
            padding: 12px;
            display: flex;
            width: fit-content;
            align-items: center;
            background-color:  #FFFFFF transparent;
                

            .circle{
                display: flex;
                align-items: center;
                min-width: 5px;
                background-color: #FFFFFF;
                border-radius: 50%;
                border:2px solid #949494;
                width: 20px;
                height: 20px;
                justify-content: center;

                .dot{
                    background-color: white;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
            }

            .square{
                display: flex;
                align-items: center;
                min-width: 5px;
                background-color: #FFFFFF;
                border:2px solid #949494;
                width: 20px;
                height: 20px;
                justify-content: center;

                .squaredot{
                    background-color: white;
                    width: 10px;
                    height: 10px;
                }
            }
        }

        .button{
            p{
                color:var(--color-primary);
                font-family: 'SpareBank1-regular';
                font-weight: 400;
                font-size: 16px;
            }
        }
    }

    &.multicolumn {
        .selector {
            min-height: 75px;
            float: left;
            width: calc(50% - 12px);
            padding: 0px 10px;
            word-break: break-word;
            -webkit-hyphens: auto;
            hyphens: auto;
            margin: 6px 6px;
            .button {
                display: flex;
                align-items: center;
            }
            
            &.oneOption {
                width: calc(100% - 10px);
            }
        }
    }
}
