@import "./Variables.module.scss";

body {
    overscroll-behavior-y: contain;
}

.app {
    transition: background-color 0.3s ease;
    background-color: #FDF8F5;
    .maincontent {
        overflow: hidden;
        padding-top: 12px;
        width: calc(100% - 286px);
        margin-left: 274px;
        //max-width: 1000px;
        min-height: 100vh;
        transition: all 0.3s ease;
        @media (max-width: 765px) {
            position: relative;
            max-width: calc(100% - 20px);
            width: 600px;
            margin: 0 auto;
            margin-top: 60px;
        }
    }

    :global(.ffe-button--primary) {
        border-color: var(--color-primary);
        background-color: var(--color-primary);
        &:hover {
            background-color: var(--color-primary-light);
            border-color: var(--color-primary-light);
        }
    }

    :global(.ffe-button--secondary) {
        border-color: var(--color-primary-light);
        color: var(--color-primary);
        &:hover {
            background-color: var(--color-primary-light);
            border-color: var(--color-primary-light);
            color: #fff;
        }
    }

    :global(.ffe-form-label), :global(.ffe-h1), :global(.ffe-h2), :global(.ffe-h3), :global(.ffe-h4), :global(.ffe-h5), :global(.ffe-h6), :global(.ffe-calendar__title), :global(.ffe-table__caption), :global(.ffe-card-body__title) {
        color: var(--color-primary);
    }

    :global(.ffe-button--action) {
        margin-top: 20px;
        width: 100%;
    }

    :global(.ffe-grid__row) {
        max-width: 100%;
        >div {
            padding-bottom: 0px;
        }
    }

    :global(.ffe-hidden-checkbox) {
        &:checked + :global(.ffe-checkbox) {
            &::before {
                border-color: var(--color-primary);
            }
            &::after {
                border-color: var(--color-primary);
            }
        }
        &:hover + :global(.ffe-checkbox) {
            &::before {
                border-color: var(--color-primary);
            }
        }
    }

    :global(.ffe-radio-switch) {
        color: var(--color-primary);
    }
    :global(.ffe-radio-input) {
        &:checked + :not(.ffe-radio-button--invalid) {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
        }
        &:checked + ::before {
            background: var(--color-primary);
        }
        &:hover + :global(.ffe-radio-switch) {
            border-color: var(--color-primary-light);
        }
        &:focus + :global(.ffe-radio-switch) {
            box-shadow: 0 0 0 3px #fff, 0 0 0 5px var(--color-primary);
        }
    }

    :global(.ffe-tooltip--open) {
        :global(.ffe-tooltip__icon) {
            border-color: var(--color-primary);
        }
    }
    :global(.ffe-tooltip__icon) {
        color: var(--color-primary);
        &:hover {
            border-color: var(--color-primary-light);
        }
        &:focus {
            box-shadow: 0 0 0 3px #fff, 0 0 0 5px var(--color-primary);
        }
    }

    :global(.ffe-tab-group) {
        border-color: var(--color-primary);
    }

    :global(.ffe-tab) {
        color: var(--color-primary);
        &:hover {
            background-color: var(--color-primary-light);
            color: #fff;
        }
        &:active {
            background-color: var(--color-primary);
            color: #fff;
        }
    }

    :global(.ffe-dropdown) {
        &:hover {
            border-color: var(--color-primary-light);
        }
        &:focus {
            box-shadow: 0px 0px 0px 2px var(--color-primary-light);
        }
    }

    :global(.ffe-input-field) {
        &:hover {
            border-color: var(--color-primary-light);
        }
        &:focus {
            box-shadow: 0px 0px 0px 2px var(--color-primary-light);
        }
    }

    :global(.ffe-tab--selected) {
        background-color: var(--color-primary);
        color: #fff;
    }

    :global(.ffe-button--action) {
        background-color: var(--color-secondary);
        &:hover {
            background-color: var(--color-secondary-light);
        }
        &[disabled] {
            background-color: var(--color-secondary-light);
            pointer-events: none;
        }
    }
}

button[disabled] {
    opacity: 0.6;
    pointer-events: none;
}

[class*="basic-singleselect"] {
    [class*="select__control"] {
        height: 45px;
        padding: 0 16px;
        font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
        background-color: #fff;
        color: #020a0a;
        border-radius: 4px;
        border: 2px solid #949494;
        transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
        width: 100%;
        font-size: 1rem;
        cursor: text;
        &:hover {
            border-color: var(--color-primary-light);
        }
        &:active, &:focus {
            border: none;
            //border-color: #fff;
            box-shadow: 0 0 0 2px var(--color-primary-light);
            outline: none;
            &:hover {
                //border: none;
            }
        }
    }
}

input[disabled] {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
}

.menucollapsed {
    .maincontent {
        width: calc(100% - 57px);
        margin-left: 57px;
        @media (max-width: 765px) {
            position: relative;
            max-width: calc(100% - 20px);
            width: 600px;
            margin: 0 auto;
            margin-top: 60px;
        }
    }
    
    [class*="_themetoggle_"] {
        max-width: 20px;
        [class*="_toggle_"] {
            display: none !important;
        }
        span {
            margin-left: 0px !important;
        }
        span:nth-of-type(1) {
            display: none;
        }
    }
}

.toast {
    >div{
        background-color: #fff;
        padding: 14px;
        [class*="Toastify__toast-icon"] {
            margin-inline-end: 32px;
            span {
                font-size: 34px;
            }
        }
        &[class*="Toastify__toast"] {
            &[class*="--default"] {
                button {
                    color: var(--color-primary);
                    opacity: 1;
                }
                [role="alert"] {
                    div {
                        color: var(--color-primary);
                        font-family: "SpareBank1-regular";
                        //font-weight: 600;
                        font-size: 16px;
                    }
                }
            }
            &[class*="--info"] {
                button {
                    color: var(--color-primary);
                    opacity: 1;
                }
                [role="progressbar"]{
                    background-color: var(--color-primary);
                }
                [role="alert"] {
                    div {
                        color: var(--color-primary);
                        font-family: "SpareBank1-regular";
                        //font-weight: 600;
                        font-size: 16px;
                    }
                }
            }
            &[class*="--success"] {
                button {
                    color: var(--color-secondary);
                    opacity: 1;
                }
                [role="progressbar"]{
                    background-color: var(--color-secondary);
                }
                [role="alert"] {
                    div {
                        color: var(--color-secondary);
                        font-family: "SpareBank1-regular";
                        //font-weight: 600;
                        font-size: 16px;
                    }
                }
            }
            &[class*="--error"] {
                button {
                    color: #ae0000;
                    opacity: 1;
                }
                [role="progressbar"]{
                    background-color: #ae0000;
                }
                [role="alert"] {
                    div {
                        color: #ae0000;
                        font-family: "SpareBank1-regular";
                        font-weight: 600;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

[class*="menutooltip"] {
    background-color: var(--color-primary) !important;
    height: 26px !important;
    font-family: "SpareBank1-regular" !important;
    font-size: 14px !important;
    border-radius: 0px !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    padding: 6px 12px !important;
}

[class*="disabledAgreementTooltip"], [class*="customTooltip"] {
    background-color: var(--color-primary) !important;
    height: 26px !important;
    font-family: "SpareBank1-regular" !important;
    font-size: 14px !important;
    border-radius: 6px !important;
    padding: 6px 12px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

:root:root {
    --toastify-toast-width: 380px;
    --toastify-font-family: "SpareBank1-regular" !important;
    --toastify-font-size: 16px !important;
    --toastify-font-weight: 600 !important;
    --toastify-text-color-light: var(--color-primary) !important;
    --toastify-text-color-info: var(--color-primary) !important;
}

[class*="select--is-disabled"] {
    
}

// Class for hiding div on print
[class*="noprint"] {
    @media print {
        display: none !important;
    }
}

// Class for showing div only on print
[class*="printonly"] {
    display: none;
    
    @media print {
        display: block;
    }
}

@media print {
    .maincontent {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0px !important;
        padding: 0px 60px;
    }

    html, body {
        height: 99%;
        //border: 2px solid green;
    }

    div {
        border: 0.1px solid transparent;
    }
    p {
        border: 0.1px solid transparent;
    }

    [class*="_maincontent_"] {
        min-height: unset !important;
    }
    
    @page {
        margin: 0;
        height: 99%;
    }
    //body { margin: 1.6cm; }
}


// html, body {
//     height: calc(100vh - 200px);
//     border: 1px solid green;
// }

// div { border: 1px solid black;}
// p { border: 1px solid red;}