:root {
  --ffe-spacing: 8px;
  --ffe-spacing-2xs: 4px;
  --ffe-spacing-xs: 8px;
  --ffe-spacing-sm: 16px;
  --ffe-spacing-md: 24px;
  --ffe-spacing-lg: 32px;
  --ffe-spacing-xl: 40px;
  --ffe-spacing-2xl: 48px;
  --ffe-spacing-3xl: 64px;
  --ffe-spacing-4xl: 80px;
  --ffe-spacing-5xl: 160px;
}
