.buttoncontainer{
    position: relative;
    width: fit-content;
    .button {
        display: flex;
        color:white;
        width: fit-content;
        flex-direction: row;
        border-radius: 96px;
        font-family: 'SpareBank1-regular',arial,sans-serif;
        font-size: 16px;
        overflow: hidden;
        position: relative;
        z-index: 2;

        .splitbutton {
            padding: 8px 24px;
            outline: none;
            width: fit-content;
            display: flex;
            align-items: center;
            border-right: 2px solid white;
            background-color: var(--color-primary);
            cursor: pointer;
        }

        .splitbuttondisabled {
            background-color: #b9d1e5;
            pointer-events: none;
            cursor: default;
        }

        .dropdown {
            background-color: var(--color-primary);
            padding: 8px 10px;
            display: flex;
            width: fit-content;
            align-items: center;
            cursor: pointer;
            span {
                transition: rotate 0.1s ease-in-out;
                rotate: 0deg;
            }
            &.dropdownopen {
                span {
                    rotate: 180deg;
                }
            }

            &.arrowdisabled {
                opacity: 0.6;
                pointer-events: none;
                cursor: default;
            }
        }
    }
    .dropdowncontent {
        position:absolute;
        top:22px;
        left:0px;
        background-color: white;
        min-width: 100%;
        z-index: 1;
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0px 0px 10px #00000021;
        padding-top: 24px;
        overflow: hidden;

        p {
            color: var(--color-primary);
            padding: 12px 24px;
            margin: 0px;
            font-weight:600;
            &:nth-last-child(1) {
                padding-bottom: 20px;
            }
            &:hover {
                background-color: var(--color-primary-light);
                color: white;
                cursor: pointer;
            }
            
            &.dropdownbuttondisabled {
                opacity: 0.6;
                pointer-events: none;
                cursor: default;
                font-weight: 300;
                // font-style: italic;
            }
        }
    }
}

/* Change the background color of the dropdown button when hovering*/
.splitbutton:hover , .dropdown:hover, .dropdownopen {
    background-color: var(--color-primary-light);
}
