:root {
    /** Primærpalett */
    --ffe-farge-fjell: var(@ffe-farge-fjell);
    --ffe-farge-fjell-70: var(@ffe-farge-fjell-70);
    --ffe-farge-fjell-30: var(@ffe-farge-fjell-30);
    --ffe-farge-vann: var(@ffe-farge-vann);
    --ffe-farge-vann-70: var(@ffe-farge-vann-70);
    --ffe-farge-vann-30: var(@ffe-farge-vann-30);
    --ffe-farge-sand: var(@ffe-farge-sand);
    --ffe-farge-sand-70: var(@ffe-farge-sand-70);
    --ffe-farge-sand-30: var(@ffe-farge-sand-30);
    --ffe-farge-frost: var(@ffe-farge-frost);
    --ffe-farge-frost-70: var(@ffe-farge-frost-70);
    --ffe-farge-frost-30: var(@ffe-farge-frost-30);
    --ffe-farge-syrin: var(@ffe-farge-syrin);
    --ffe-farge-syrin-70: var(@ffe-farge-syrin-70);
    --ffe-farge-syrin-30: var(@ffe-farge-syrin-30);

    /** Støttefarger */
    --ffe-farge-myrull: var(@ffe-farge-myrull);
    --ffe-farge-myrull-70: var(@ffe-farge-myrull-70);
    --ffe-farge-myrull-30: var(@ffe-farge-myrull-30);
    --ffe-farge-villblomst: var(@ffe-farge-villblomst);
    --ffe-farge-villblomst-70: var(@ffe-farge-villblomst-70);
    --ffe-farge-villblomst-30: var(@ffe-farge-villblomst-30);
    --ffe-farge-nordlys: var(@ffe-farge-nordlys);
    --ffe-farge-nordlys-70: var(@ffe-farge-nordlys-70);
    --ffe-farge-nordlys-30: var(@ffe-farge-nordlys-30);
    --ffe-farge-lyng: var(@ffe-farge-lyng);
    --ffe-farge-lyng-70: var(@ffe-farge-lyng-70);
    --ffe-farge-lyng-30: var(@ffe-farge-lyng-30);
    --ffe-farge-baer: var(@ffe-farge-baer);
    --ffe-farge-baer-70: var(@ffe-farge-baer-70);
    --ffe-farge-baer-30: var(@ffe-farge-baer-30);
    --ffe-farge-skog: var(@ffe-farge-skog);
    --ffe-farge-skog-70: var(@ffe-farge-skog-70);
    --ffe-farge-skog-30: var(@ffe-farge-skog-30);
    --ffe-farge-multe: var(@ffe-farge-multe);
    --ffe-farge-multe-70: var(@ffe-farge-multe-70);
    --ffe-farge-multe-30: var(@ffe-farge-multe-30);
    --ffe-farge-sol: var(@ffe-farge-sol);
    --ffe-farge-sol-70: var(@ffe-farge-sol-70);
    --ffe-farge-sol-30: var(@ffe-farge-sol-30);

    /** Nøytrale farger */
    --ffe-farge-natt: var(@ffe-farge-natt);
    --ffe-farge-svart: var(@ffe-farge-svart);
    --ffe-farge-koksgraa: var(@ffe-farge-koksgraa);
    --ffe-farge-moerkgraa: var(@ffe-farge-moerkgraa);
    --ffe-farge-graa: var(@ffe-farge-graa);
    --ffe-farge-lysgraa: var(@ffe-farge-lysgraa);
    --ffe-farge-moerkvarmgraa: var(@ffe-farge-moerkvarmgraa);
    --ffe-farge-varmgraa: var(@ffe-farge-varmgraa);
    --ffe-farge-lysvarmgraa: var(@ffe-farge-lysvarmgraa);
    --ffe-farge-hvit: var(@ffe-farge-hvit);

    /** Font sizes */
    --ffe-fontsize-body-text: 1rem;
    --ffe-fontsize-small-text: 0.875rem;
    --ffe-fontsize-micro-text: 0.75rem;
    --ffe-fontsize-lead-paragraph: 1.125rem;
    --ffe-fontsize-sub-lead-paragraph: 1.063rem;
    --ffe-fontsize-h1: 1.75rem;
    --ffe-fontsize-h2: 1.5rem;
    --ffe-fontsize-h3: 1.25rem;
    --ffe-fontsize-h4: 1.125rem;
    --ffe-fontsize-h5: 1.0625rem;
    --ffe-fontsize-h6: 1rem;
    --ffe-fontsize-form-input: 1rem;
    --ffe-fontsize-form-dropdown: 1rem;
    --ffe-fontsize-button: 1rem;

    /** Theme base colors */

    /* Links, buttons and similar */
    --ffe-g-primary-color: var(--ffe-farge-vann);

    /* Headings, labels, hover, etc */
    --ffe-g-secondary-color: var(--ffe-farge-fjell);

    /* Error messages, invalid inputs, etc  */
    --ffe-g-error-color: var(--ffe-farge-baer);

    /** Theme borders */

    /* Form element borders */
    --ffe-g-border-color: var(--ffe-farge-varmgraa);
    --ffe-g-border-radius: 4px;

    /** Fonts */

    /* Default font */
    --ffe-g-font: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;

    /* h1 - h2 */
    --ffe-g-font-heading: 'SpareBank1-title-medium', 'MuseoSansRounded-700',
        arial, sans-serif;

    /* h3 - h6 */
    --ffe-g-font-heading-small: 'SpareBank1-medium', 'MuseoSansRounded-700',
        arial, sans-serif;

    /* Bold text */
    --ffe-g-font-strong: 'SpareBank1-medium', 'MuseoSansRounded-700', arial,
        sans-serif;

    /* Emphasized text */
    --ffe-g-font-italic: 'SpareBank1-italic', 'MuseoSans-500', arial, sans-serif;

    /** Typography */

    /* Default body text color */
    --ffe-g-text-color: var(--ffe-farge-svart);

    /* Lead paragraph */
    --ffe-g-lead-color: var(--ffe-farge-fjell);

    /* Headings */
    --ffe-g-heading-color: var(--ffe-farge-fjell);

    /* Links */
    --ffe-g-link-color: var(--ffe-farge-vann);
    --ffe-g-link-color-hover: var(--ffe-farge-fjell);
    --ffe-g-link-color-visited: var(--ffe-farge-lyng);
    --ffe-g-link-icon-color-hover: var(--ffe-farge-natt);
    --ffe-g-link-icon-color-focus: var(--ffe-farge-vann);
}