.fullpagehelp {
    position: fixed;
    background-color: #0000008f;
    width: 100%;
    height: 100%;
    z-index: 10000;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.1s linear;
    padding: 20px;
    overflow-y: auto;
    overscroll-behavior: contain;
    .helppage {
        width: 1000px;
        max-width: 100%;
        margin: auto;
        margin-top: 60px;
        padding: 40px;
        background-color: white;
        box-shadow: 2px 2px 20px #00000057;
        position: relative;
        .helppageheader {
            h1 {
                font-weight: 500;
                font-family: "SpareBank1-title-medium";
                font-size: 28px;
                margin-top: 0px;
                margin-bottom: 20px;
                color: var(--color-primary);
            }
            .closebtn {
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
                padding: 20px;
            }
        }
        .helppagecontent {
            p {
                font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
                font-feature-settings: "tnum";
                font-variant-numeric: tabular-nums;
                font-size: 1rem;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                color: var(--color-primary);
            }
        }
    }


    &.hidden {
        visibility: hidden;
        opacity: 0;
    }
}