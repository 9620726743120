@import "./Variables.module.scss";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

input[class*="ffe-input-field"][aria-invalid="true"] {
    margin-bottom: 32px !important;
}

div[class*="problemstooltip"] {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    p {
        color: #1e1e1e;
        margin: 0px;
        margin-bottom: 10px;
        font-size: 14px;
        &::before {
            content: "⚠";
            color: #e12d2f;
            margin-right: 5px;
        }
    }
}

// Dark mode overrides -- START
[class*="_app_"] {
    &[class*="dark"] {
        background-color: #363636;

        h1, h2, h3, h4, h5, h6, p, label, legend, [class*="material-symbols-outlined"], [class*="ffe-tooltip__text"]>div {
            color: #ececec;
        }

        button {
            color: #1e1e1e;
        }

        &[class*="_menucollapsed_"] {
            [class*="_themetoggle_"] {
                span:nth-of-type(2) {
                    display: none;
                }
                span:nth-of-type(1) {
                    display: block;
                }
            }
        }

        [class*="_login_"] {
            background-color: #1e1e1e;
            [class*="_logincontainer_"] {
                h1 {
                    color: #ececec;
                }
            }
            [class*="_welcomeimage_"] {
                svg {
                    >path:nth-of-type(1) {
                        fill: #5c5c5c;
                    }
                }
            }
        }

        [class*="ffe-button--secondary"] {
            background-color: #1e1e1e;
            color: #ececec;
            border-color: #ececec;
            &:hover {
                background-color: #666666;
                color: #ececec;
            }
        }

        [class*="ffe-button--action"] {
            background-color: #ececec;
            color: #1e1e1e;
            border-color: #ececec;
            &:hover {
                background-color: #666666;
                color: #1e1e1e;
            }
        }

        [class*="_sidemenu_"] {
            background-color: #1e1e1e;
            color: #ececec;
            hr {
                border: 1px solid rgb(128 128 128 / 31%) !important;
            }
            [class*="_navcontainercontent_"] {
                background-color: #333333 !important;
            }
        }
        
        .themeselector {
            [class*="_themetoggle_"] {
                [class*="_toggle_"] {
                    [class*="_circle_"] {
                        background-color: #1e1e1e !important;
                        margin-left: 22px !important;
                    }
                }
            }
        }

        [class="ffe-header"] {
            [class="ffe-header__wrapper"] {
                background-color: #1e1e1e !important;
                [class*="_mobilemenubtn_"] {
                    span, &::before, &::after {
                        background: #ececec !important;
                    }
                }
                [class*="_mobilenav_"] {
                    background-color: #1e1e1e !important;
                    [class*="_mobilenavdealerswitcher_"] {
                        [class*="_navdealerswitcher_"] {
                            background-color: #1e1e1e !important;
                            [class*="accounticon"] {
                                color: #ececec !important;
                                background-color: #1e1e1e !important;
                            }
                            p {
                                span {
                                    color: #ececec !important;
                                }
                            }
                        }
                    }
                    [class*="_themetoggle_"] {
                        [class*="_toggle_"] {
                            background-color: #ececec !important;
                            [class*="_circle_"] {
                                background-color: #1e1e1e !important;
                            }
                        }
                    }
                }
            }
        }

        [class*="_navdealerswitcher_"] {
            border-right: 4px solid #666666;
            [class*="material-symbols-outlined"] {
                color: #1e1e1e;
            }
            p {
                color: #1e1e1e;
            }
            [class*="dropdowncontent"] {
                p {
                    &:hover {
                        background-color: #aeaeae;
                    }
                }
            }
        }

        [class*="_card_"] {
            background-color: #1e1e1e;
        }

        [class*="ffe-tab-group"] {
            background-color: #1e1e1e;
            border-color: #ececec;
            [class*="ffe-tab"] {
                color: #ececec;
                background-color: #1e1e1e;
                &:hover {
                    background-color: #666666;
                    color: #ececec;
                }
                &[class*="ffe-tab--selected"] {
                    background-color: var(--color-primary-light);
                    color: #ececec;
                }
            }
        }

        [class*="_selectorlist_"] {
            [class*="_selector_"] {
                background-color: #1e1e1e;
                color: #ececec;
                border-color: #ececec;
                [class*="_button_"] {
                    p {
                        color: #ececec;
                    }
                }
                &:hover {
                    background-color: #666666;
                    color: #ececec;
                }
                &[class*="_active_"] {
                    background-color: var(--color-primary-light);
                    color: #1e1e1e;
                    [class*="_dot_"] {
                        background-color: var(--color-primary-light) !important;
                    }
                    [class*="_button_"] {
                        p {
                            color: #ececec;
                        }
                    }
                }
            }
        }

        [class*="_roundbuttongroup_"] {
            [class*="_roundbutton_"] {
                background-color: #1e1e1e;
                border-color: #ececec;
                color: #ececec;
                &:nth-child(2) {
                    background-color: #1e1e1e;
                }
                &:hover {
                    background-color: #666666;
                    color: #ececec;
                }
                &[class*="_active_"] {
                    background-color: #ececec;
                    color: #1e1e1e;
                }
            }
        }

        [class*="_affixinput_"] {
            [class*="_numberdisplay_"] {
                background-color: #1e1e1e;
                color: #ececec;
            }
        }

        [class*="ffe-input-field"], [class*="ffe-dropdown"], [class*="ffe-textarea"], [class*="_searchfieldcontainer_"] {
            background-color: #1e1e1e;
            color: #ececec;
            border-color: #ececec;
            &:hover {
                border-color: #ececec;
            }
            &:focus {
                border-color: #ececec;
            }
            [class*="ffe-input-field__input"] {
                color: #ececec;
            }
        }

        [class*="ffe-dropdown"] {
            color: #ececec;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%201000%201000%22%20width%3D%2218%22%20height%3D%2218%22%20xml%3Aspace%3D%22preserve%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23ececec%22%20d%3D%22M111.1%20228.6c19.2%200%2038.4%207.3%2053%2022l336.7%20336.7%20334.5-334.5c29.3-29.3%2076.8-29.3%20106.1%200%2029.3%2029.3%2029.3%2076.8%200%20106.1L553.8%20746.3c-29.3%2029.3-76.8%2029.3-106.1%200L58.1%20356.6c-29.3-29.3-29.3-76.8%200-106.1C72.7%20235.9%2091.9%20228.6%20111.1%20228.6z%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
        }

        [class*="ffe-radio-switch"] {
            background-color: #1e1e1e;
            &:hover {
                background-color: #666666;
                border-color: #ececec;
            }
            &:checked {
                background-color: var(--color-primary-light);
                border-color: #ececec;
            }
        }

        [class*="ffe-radio-input"] {
            &:checked {
                + [class*="ffe-radio-switch"] {
                    background-color: var(--color-primary-light);
                    border-color: #ececec;
                    &::before {
                        background-color: var(--color-primary-light);
                    }
                }
            }
        }

        [class*="basic-singleselect"] {
            [class*="select__control"] {
                background-color: #1e1e1e;
                color: #ececec;
                border-color: #ececec;
                &:hover {
                    border-color: #ececec;
                }
                &:focus {
                    border-color: #ececec;
                }
                [class*="select__value-container"] {
                    color: #ececec;
                    [class*="select__single-value"] {
                        color: #ececec;
                    }
                }
            }
        }

        [class*="_calculator_"] {
            [class*="_costoverview_"] {
                [class*="_resultslist_"] {
                    >p:nth-of-type(1) {
                        color: #ececec !important;
                        text-decoration: underline;
                    }
                    [class*="_result_"] {
                        p {
                            color: #ececec;
                        }
                    }
                }
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #666666;
                }
                &::-webkit-scrollbar-thumb {
                    background: #464646;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #313131;
                }
            }
        }

        [class*="_loanitemslist_"] {
            >p {
                color: #ececec;
            }
            [class*="_archiveitem_"] {
                h2 {
                    color: #ececec;
                }
                &[class*="_activeloanitem_"] {
                    border-left: 4px solid #ececec;
                }
                &:hover {
                    border-left: 4px solid #ececec;
                    h2 {
                        color: #ececec;
                        text-decoration: underline;
                    }
                }
            }
        }

        [class*="_pill_"] {
            border-color: #ececec;
        }

        [class*="_searchfieldcontainer_"] {
            input {
                background-color: #1e1e1e;
                color: #ececec;
                &::placeholder {
                    color: #ececec;
                }
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #ececec;
                }
                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #ececec;
                }
            }
        }

        [class*="_loanoverview_"], [class*="_insuranceoverview_"] {
            [class*="_loaninfoarea_"],
            [class*="_loancaseinfoarea_"],
            [class*="_loandetails_"],
            [class*="_insurance_"],
            [class*="_personalinfo_"],
            [class*="_vehicle_"] {
                h2, span, p {
                    color: #ececec;
                }
            }
        }

        [class*="_insuranceoverview_"] {
            [class*="_blurtext_"] {
                p {
                    background-color: #1e1e1e !important;
                    border: 1px solid #ececec;
                }
            }
        }

        [class*="SplitButton_buttoncontainer_"] {
            [class*="SplitButton_button_"] {
                [class*="SplitButton_splitbutton_"], [class*="SplitButton_dropdown_"] {
                    background-color: #525252;
                    color: #fff;
                    &:hover {
                        border-color: #ececec;
                    }
                    &:focus {
                        border-color: #ececec;
                    }
                    &[class*="SplitButton_splitbuttondisabled_"] {
                        color: #616161;
                        background-color: #2f2f2f;
                    }
                }
                [class*="SplitButton_dropdown_"] {
                    border-radius: 0px 22px 22px 0px;
                }
            }
            [class*="SplitButton_dropdowncontent_"] {
                background-color: #1e1e1e;
                border: 2px solid #ececec;
                border-radius: 0px 0px 22px 22px;
                p {
                    color: #ececec;
                    &:hover {
                        background-color: #666666;
                    }
                }
            }
        }

        [class="Toastify"] {
            [class*="Toastify__toast-container"] {
                [class*="Toastify__toast"] {
                    background-color: #1e1e1e;
                    [class="Toastify__toast-body"] {
                        div:nth-of-type(1) {
                            color: #ececec;
                        }
                        div:nth-of-type(2) {
                            color: #ececec;
                        }
                    }
                    >button {
                        color: #ececec;
                    }
                    [class*="Toastify__progress-bar"] {
                        background-color: #ececec;
                    }
                }
            }
        }

        [class*="ffe-loading-spinner"] {
            &::before {
                border-color: #ececec;
                border-top-color: transparent;
            }
        }

        [class*="_messages_"] {
            [class*="_floatingbutton_"] {
                background-color: #1e1e1e;
                color: #ececec;
            }
            [class*="_container_"] {
                background-color: #363636;
                color: #ececec;
                [class*="_tabs_"] {
                    [class*="_tab_"] {
                        h3 {
                            color: #ececec;
                        }
                        &[class*="_active_"] {
                            border-bottom-color: #ececec;
                        }
                    }
                }
                [class*="_notifications_"] {
                    [class*="_new_"], [class*="_archive_"] {
                        ul {
                            li {
                                span, h3, p {
                                    color: #ececec;
                                }
                            }
                        }
                        >p {
                            color: #ececec;
                        }
                    }
                }
            }
        }

        [class*="_fullpagehelp_"] {
            [class*="_helppage_"] {
                background-color: #363636;
                [class*="_helppageheader_"] {
                    h1 {
                        color: #ececec;
                    }
                }
                [class*="_helppagecontent_"] {
                    color: #ececec;

                    p, li, h1, h2, h3, h4, h5, h6, span {
                        color: #ececec;
                    }
                }
            }
        }

        [class*="_contactmodal_"] {
            [class*="_content_"] {
                background-color: #363636;
                [class*="_helppageheader_"] {
                    h1 {
                        color: #ececec;
                    }
                }
                [class*="_helppagecontent_"] {
                    p {
                        color: #ececec;
                    }
                    [class*="_contactitem_"] {
                        background-color: #1e1e1e;
                        p, h3, span {
                            color: #ececec;
                        }
                    }
                }
            }
        }

        [class*="_feedbackModal_"] {
            [class*="_content_"] {
                background-color: #363636;
                [class*="_feedbackHeader_"] {
                    h1 {
                        color: #ececec;
                    }
                }
            }
        }

        [class*="_statistics_"] {
            [class*="_progressbar_"] {
                [class*="_label_"], [class*="_minlabel_"], [class*="_maxlabel_"] {
                    color: #ececec;
                }
                [class*="_marker_"] {
                    [class*="_markerlabel_"] {
                        color: #ececec;
                    }
                }
            }
            [class*="_statisticscompetitiontable_"] {
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #666666;
                }
                &::-webkit-scrollbar-thumb {
                    background: #464646;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #313131;
                }
                /* tbody {
                    tr:nth-child(1) {
                        background: linear-gradient(270deg, #d3d3ea31 26.01%, rgba(211, 211, 234, 0) 96.23%);
                    }
                } */
                th {
                    color: #ececec;
                }
                td {
                    color: #ececec;
                }
            }
            [class*="_doughnutchart_"] {
                p[class*="_centertext_"] {
                    color: #ececec;
                }
            }
        }

        [class*="InsurancePricingCard_pricecontainer_"] {
            [class*="_pricelist_"] {
                [class*="_pricelistitem_"] {
                    &[class*="_active_"] {
                        background-color: #424242;
                        color: #ececec;
                    }
                    &:hover {
                        background-color: #636363;
                    }
                }
            }
            [class*="_tooltip_"] {
                color: #ececec;
            }
        }

        [class*="FileUpload_border_"] {
            border: 7px solid #1e1e1e;
            background-color: #1e1e1e;
            [class*="FileUpload_fileuploadcontainer_"] {
                background-color: #1e1e1e;
                border: 1px dashed #ececec;
                [class*="FileUpload_fileuploadbutton_"] {
                    background-color: #1e1e1e;
                    border: 2px solid #ececec;
                }
            }
            &[class*="_fileover_"] {
                [class*="FileUpload_fileuploadcontainer_"] {
                    border: 3px dashed #ececec;
                }
            }
        }

        [class*="FileList_attachments_"] {
            border: 2px solid #ececec;
        }

        [class*="Loanapplication_grid"] {
            [class*="Loanapplication_loaninfoarea"] {
                h2, p {
                    color: #ececec;
                    span {
                        color: #ececec;
                    }
                }
            }
            [class*="Loanapplication_loancaseinfoarea"] {
                h2, p {
                    color: #ececec;
                    span {
                        color: #ececec;
                    }
                }
            }
            [class*="Loanapplication_loancasebuttons"] {
                button {
                    color: #ececec;
                }
            }
        }

        [class*="ContentCard_card"] {
            p {
                color: #ececec;
            }
        }
    }
}

[class*="menutooltip"], [class*="disabledAgreementTooltip"], [class*="customTooltip"] {
    &[class*="dark"] {
        background-color: #1e1e1e !important;
    }
}
// Dark mode overrides -- END