.affixinput {
    position: relative;
    .prefix {
        position: absolute;
        top: 14px;
        padding-left: 16px;
        pointer-events: none;
        font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
        color: gray;
    }
    
    .suffix {
        position: absolute;
        top: 14px;
        padding-left: 22px;
        pointer-events: none;
        font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
        color: gray;
    }

    .numberdisplay {
        font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
        background-color: white;
        line-height: 24px;
        padding-left: 2px;
        position: absolute;
        top: 11px;
        pointer-events: none;
        padding-right: 20px;
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
    }
    [disabled]+.numberdisplay {
        background-color: whitesmoke;
    }

    input:focus + .numberdisplay {
        display: none;
    }
}