@import "../../../Variables.module.scss";

.card {
    padding: 16px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin-bottom: 16px;
    
    .topleftbutton {
        position: absolute;
        top: 8px;
        left: 7px;
        cursor: pointer;
    }

    &:not([class*=disabled]) {
        h4 {
            cursor: pointer;
        }
    }

    &[class*=disabled] {
        opacity: 0.5;
        cursor: default;
    }

    .materialicon {
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 30px;
        color: var(--color-primary);
    }

    h4 {
        font-weight: 500;
        max-width: calc(100% - 22px);
        font-family: 'SpareBank1-title-medium';
        font-size: 24px;
        margin-bottom: 0px;
        text-align: center;
    }

    .content {
        margin-top: 22px;
    }

    &[class*=notitle] {
        .content {
            margin-top: 0px;
        }
    }

    >span {
        cursor:pointer;
    }

    &[class*=disableclick] {
        cursor: default;
        h4 {
            cursor: default;
        }
    }

    &[class*=loading] > .content {
        filter: blur(5px);
    }

    .loadingtext {
        text-align: center;
        position: absolute;
        top: 70px;
        width: calc(100% - 32px);
        font-size: 20px;
    }
    &[class*=displayFlex] {
        [class*="content"] {
            display: flex;
            flex-direction: column;
        }
    }
}