.defaultLogo {
    width: 200px;
    max-height: 70px;
}

img.defaultLogo {
    margin: 40px;
    margin-top: 60px;
}

:export {
    colorPrimary: var(--color-primary);
}